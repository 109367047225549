.Dropdown{

	
	position:relative;
	
	
	*{
		@extend %u-text--body;
	}

	
}
.Dropdown-arrow{
	position:absolute;
	top:0;
	right:0;
	border-left:1px solid rgba(255,255,255,0.5);
	height:100%;
	width:40px;
	.Icon{
		position:absolute;
		top:50%;
		left:50%;
		transition:transform 0.4s ease;
		transform:translate(-50%,-50%) rotate(0deg);
		width:20px;
		height:18px;
		font-size:14px!important;
		line-height:18px!important;
		&:before{
			font-size:14px!important;
			line-height:18px!important;
			text-align:center;
		}

	}
}
.Dropdown-head{
	padding:10px;
	border:1px solid rgba(255,255,255,0.5);
	max-width:300px;
	position:relative;
	cursor:pointer;
	@include margin-for-menu-plus();
	background:$darkBlue;
	transition:background 0.4s ease, border 0.4s ease;

	@include respond-to('mobile'){
		margin:0 auto;
	}

}
.Dropdown-body{
	position:relative;
	display:none;
	background:$altBlue;
	margin-top:20px;
	@include pad-for-menu();
	padding-top:40px;
	padding-bottom:40px;
	padding-right:40px;

	@include respond-to('mobile'){
		padding-left:20px;
		padding-right:20px;
	}

}

.Dropdown.is-active{
	
	.Dropdown-head{
		background:$purple;
		border-color:transparent;

		.Icon{
			transform:translate(-50%,-50%) rotate(90deg);
		}
	}

	.Dropdown-body{
		display:block;
	}
}



.Dropdown-grid{

	& > div{
		&:before{
			content:'';
			display:block;
			border-top:1px solid rgba(255,255,255,0.5);
			margin-bottom:20px;
		}

		padding-bottom:40px;
	}
	

}