.Folio {
	position: relative;
	overflow: hidden;
	padding: 100px 0;
	@include respond-to("tablet-up") {
		margin-top: -100px;
	}

	@include respond-to("wide") {
		margin-top: -40px;
	}
	padding-bottom: 200px;

	@include respond-to("mobile") {
		padding: 0;
	}
}

.slick-list {
	overflow: visible !important;
}

.Folio-body {
	position: relative;
	z-index: 2;
}

.Folio-item {
	@include respond-to("mobile") {
		height: 70vw;
		position: relative;
	}
}

.Folio-cta {
	@include respond-to("tablet-up") {
		position: absolute;
		bottom: 20px;
		left: 280px;
		z-index: 5;
	}

	@include respond-to("tablet") {
		left: 40px;
	}

	@include respond-to("mobile") {
		padding: 0 20px;
		padding-top: 50px;
	}

	a {
		@extend %u-text--body;
		@extend %u-link--arrow;
	}
}

.Folio-slide {
	position: relative;
	width: 85vw;
	@include respond-to("mobile") {
		width: 100vw;
	}
	transition: opacity 0.3s ease;
	z-index: 1;
	&:after {
		content: "";
		display: block;
		@include pad-ratio(6, 2);
	}

	&.slick-center {
		z-index: 2;
	}
}

.Folio {
	.slick-next {
		position: absolute;
		top: 50%;
		right: 20px;

		@include respond-to("mobile") {
			right: 0;
		}
		transition: transform 0.4s ease;
		.Site--no-touch & {
			&:hover {
				transform: translateX(10px);
			}
		}
		&:focus {
			outline: none;
		}
		width: 50px;
		padding-top: 50px;
		height: 0;
		overflow: hidden;
		&:before {
			@extend %u-iconfont;
			@extend %u-iconfont--arrow-right;
			font-size: 20px;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 0;
			width: 50px;
		}
	}

	.slick-dots,
	.slick-prev {
		display: none !important;
	}
}

.Folio-nav {
	@include respond-to("tablet-up") {
		position: absolute;
		left: 0px;
		top: 0px;
		width: 40%;
		height: 100%;
		padding-top: 80px;
		left: 40px;
	}

	@include respond-to("desktop") {
		left: 140px;
		padding-left: 140px;
	}

	@include respond-to("tablet") {
		width: 33%;
	}
	@include respond-to("wide") {
		width: 33%;
	}

	@include respond-to("mobile") {
		margin: 0 20px;
		margin-bottom: 20px;
	}
	color: white;
	z-index: 3;
}
.Folio-list-item {
	padding: 10px;
	padding-left: 0;
	padding-bottom: 20px;
	border-top: 1px solid rgba(255, 255, 255, 0.2);
	opacity: 0.5;
	cursor: pointer;

	@include respond-to("mobile") {
		display: none;
	}

	&.is-active {
		opacity: 1;

		@include respond-to("mobile") {
			display: block;
		}
	}
}

.Folio-item {
	position: absolute;
	top: 0px;

	left: 60%;
	right: 20px;

	@include respond-to("tablet") {
		left: 50%;
		right: 40px;
	}

	@include respond-to("wide") {
		left: 50%;
		right: 40px;
	}

	@include respond-to("mobile") {
		left: 0px;
		right: 0px;
	}

	bottom: 0px;
	transition: transform 0.8s ease;
	opacity: 0.2;

	.Image {
		box-shadow: 0 0 80px rgba(128, 40, 211, 0.3);
	}

	.slick-center & {
		opacity: 1;
		transition: transform 0.5s 0.2s ease;
		@include respond-to("tablet-up") {
			transform: scale(1.1, 1.1);
		}
	}
}

.Folio-count {
	display: inline-block;
	vertical-align: top;
	@extend %u-text--heading;
}

.Folio-list-detail {
	display: inline-block;
	vertical-align: top;
	padding-left: 10px;
	padding-top: 5px;
	h1 {
		@extend %u-text--body;
	}
	h3 {
		@extend %u-text--small;
	}
	a {
		@extend %u-text--small;
		@extend %u-link--arrow;
		color: $turquoise;
		opacity: 0;
		transform: translateY(10px);
		transition: transform 0.4s ease, opacity 0.4s;
		pointer-events: none;

		.is-active & {
			opacity: 1;
			transform: none;
			pointer-events: all;
		}
	}
}

.Folio-charts {
	text-align: right;
	& > div {
		display: inline-block;
		vertical-align: top;
	}

	transform-origin: 100% 50%;
	transform: scale(0.8, 0.8);

	@include respond-to("tablet") {
		transform-origin: 100% 50%;
		transform: scale(0.7, 0.7);
	}

	@include respond-to("wide") {
		transform-origin: 100% 50%;
		transform: none;
	}
}
.Folio-foot {
	@include respond-to("tablet-up") {
		position: absolute;
		bottom: 0;
		right: 7vw;

		left: 30%;
	}

	@include respond-to("mobile") {
		width: 100%;
		white-space: nowrap;
	}

	@include respond-to("desktop") {
		left: 50%;
	}
	@include respond-to("wide") {
		left: 50%;
		right: 7vw;
	}

	z-index: 3;
}

.slick-center + .Folio-slide .Folio-item {
	@include respond-to("tablet-up") {
		//transform: translateX(-95%);
	}
}
