.Map-marker{

	width:5px;
	height:5px;
	background:transparent;
	overflow:visible!important;

	
}

.Map-card{
	position:absolute;
	
	width:150%;
	height:150%;
	top:50%;
	left:50%;
	transform:translate(-50%,-50%);

	
}


.Map-card-fov{
	position:absolute;
	background:transparent;
	width:50px;
	height:50px;
	
	top:50%;
	left:50%;
	transform:translate(-50%,-100%);
	pointer-events:none;
	//border:1px solid black;


	.fov{
		content:'';
		display:block;
		width:200px;
		height:200px;
		background:url(/ui/img/icons/fov.svg) bottom center no-repeat;
		position:absolute;
		bottom:2px;
		left:50%;
		margin-left:-100px;
		transform:rotate(180deg);
		transform-origin:50% 100%;
		z-index:1;
	}
	
}

.Map-card-dot{
	position:absolute;
	background:black;
	width:50px;
	height:50px;
	background:transparent;
	top:50%;
	left:50%;
	transform:translate(-50%,-100%);
	pointer-events:all;
	//border:1px solid black;

	
	&:after{
		content:'';
		display:block;
		width:50px;
		height:50px;
		background:url(/ui/img/icons/map-marker.svg) bottom center no-repeat;
		position:absolute;
		top:0;
		left:0;
		z-index: 2;
	}
}
