/*

	Menu-based sizes

*/

$menu-width: 2/18 * 100vw;
$col: 1/18 * 100vw;
$margin: $col/8;

@mixin menu-width() {
	width: 100%;

	@include respond-to("desktop") {
		width: $menu-width;
	}
}

@mixin margin-for-menu() {
	@include respond-to("desktop") {
		margin-left: $menu-width;
	}
}
@mixin margin-for-menu-plus() {
	@include respond-to("desktop") {
		margin-left: $menu-width + $col;
	}
}
@mixin pad-for-menu() {
	@include respond-to("desktop") {
		padding-left: $menu-width + $col;
	}
}

@mixin footerHeight() {
	height: auto;
	min-height: 600px;
}
