html,
body,
.Site,
#root {
	//height:100%;
	//overflow-x:hidden;
}

html {
	background: $new-dark;
	transition: background 1s ease;

	&.Html--loadComplete {
		background: $new-light;

		/*background: linear-gradient($new-light, rgba($new-pink, 1));
		background-attachment: fixed;
		background-size: 100% 300vh;*/
	}
}

.Site-page {
	min-height: 100%;
}
.Site-strapline {
	width: 60%;
}

html {
	//background:#EEE;
}

[data-layout="hstack"] {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	gap: 10px;

	&[data-gap="sm"] {
		gap: 5px;
	}
	&[data-gap="md"] {
		gap: 20px;
	}
	&[data-gap="lg"] {
		gap: 40px;
	}
}
[data-layout="vstack"] {
	display: flex;
	flex-direction: column;
	gap: 10px;

	&[data-gap="sm"] {
		gap: 5px;
	}
	&[data-gap="md"] {
		gap: 20px;
	}
	&[data-gap="lg"] {
		gap: 40px;
	}
}

[data-layout="cols"] {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	gap: 20px;
	flex-wrap: wrap;

	@include respond-to("mobile") {
		gap: 10px;
	}

	&[data-cols="3"] {
		@include respond-to("mobile") {
			gap: 5px;
		}
		& > * {
			// 20 is the gutter
			// 2 is the number of gutters (cols - 1)
			// divided by number of cols
			// converted to pixels
			flex: 0 calc(33% - (((20 * 2) / 3) * 1px));

			@include respond-to("mobile") {
				flex: 0 calc(33% - (((5 * 2) / 3) * 1px));
			}
		}
	}

	&[data-cols="2"] {
		& > * {
			// 20 is the gutter
			// 1 is the number of gutters (cols - 1)
			// divided by number of cols
			// converted to pixels
			flex: 0 calc(50% - (((20 * 1) / 2) * 1px));

			@include respond-to("mobile") {
				flex: 0 calc(50% - (((10 * 1) / 2) * 1px));
			}
		}
	}
}

.Site-icon {
	display: inline-block;
	width: 42px;
	height: 42px;

	@include respond-to("mobile") {
		width: 35px;
		height: 35px;
	}
	position: relative;
	border: 1px solid;
	border-radius: 50%;

	&:after {
		content: "";
		text-transform: lowercase;
		font-family: "Linearicons";
		font-size: 20px;

		@include respond-to("mobile") {
			font-size: 16px;
		}
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	&.Site-icon--small {
		border: none;
		width: 25px;
		height: 25px;
		&:after {
			font-size: 14px;
		}
	}

	&[data-icon] {
		&:after {
			content: attr(data-icon);
		}
	}
}

.Site-head {
	position: absolute;
	top: 0;
	left: 0;
	@include menu-width;

	/*@include respond-to("mobile") {
		height: 60px;
		bottom: auto;
		border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	}
	@include respond-to("tablet") {
		height: 60px;
		bottom: auto;
		border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	}
	@include respond-to("desktop") {
		border-right: 1px solid rgba(255, 255, 255, 0.2);
	}*/

	z-index: 5;
	transition: opacity 0.6s ease, transform 0.6s ease, background 0.6s ease,
		box-shadow 0.6s ease;
	transform: translateX(0px);
	opacity: 0;
	.Site--introComplete &,
	.Site--loadComplete & {
		opacity: 1;
		transform: none;
	}

	.Site--modal &,
	.Site--scrolled & {
		background: $base-background;
		@include respond-to("desktop") {
			background: transparent;
		}
	}

	.Site--menu & {
		background: transparent !important;
	}
}

.Site-intro {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 10;
	transition: opacity 1s ease;
	pointer-events: none;
	.Site--loadComplete & {
		opacity: 0;
	}
}

.Site-content {
	position: relative;
	min-height: 90vh;
}

.Site-burger {
	color: white;

	position: fixed;
	display: block !important;

	right: 20px;
	top: 16px;

	@include respond-to("desktop") {
		right: 40px;
		top: 30px;
	}

	cursor: pointer;

	/*@include respond-to(mobile) {
		display: block;
		position: fixed;

		z-index: 3;
		width: $base-icon-size;
		height: $base-icon-size;
	}*/

	@include respond-to("mobile") {
		bottom: 40px;
		top: auto;
		left: 50%;
		right: auto;
		transform: translateX(-50%);
	}
}

.Site-menu {
	position: fixed;
	top: 20px;
	left: 20px;
	bottom: 20px;
	right: 20px;
	background: $new-blue;
	border-radius: 30px;
	a {
		color: white;
	}
	pointer-events: none;
	opacity: 0;
	transition: opacity 0.5s ease;
	z-index: 4;
	.Site--menu & {
		opacity: 1;
		pointer-events: all;
	}
}

.Site-social {
	display: none;
	@include respond-to("desktop") {
		/*display: block;
		position: fixed;
		bottom: 40px;
		left: 40px;
		z-index: 10;*/
	}
}

/*

Footer background layer...

*/

.Site {
	position: relative;
}

.Site-foot-bg {
	content: "";
	position: absolute;
	left: 2/18 * 100vw;
	right: 2/18 * 100vw;

	@include respond-to("mobile") {
		left: 20px;
		right: 20px;
	}
	bottom: 0;
	background: $new-blue;
	z-index: -1;

	@include footerHeight;
	border-top-left-radius: 30px;
	border-top-right-radius: 30px;
	opacity: 0;
	transition: opacity 1s 0.5s ease;
	.Html--loadComplete & {
		opacity: 1;
	}
}

.Site-foot {
	padding: 20px 2/18 * 100vw;
	padding-top: 0.5/18 * 100vw;
	padding-bottom: 20px;

	color: white;
	@include footerHeight;
	position: relative;

	@include respond-to("mobile") {
		padding-right: 20px;
		padding-left: 20px;
	}

	&:before {
		content: "";
		display: block;
		background: red;
		height: 300px;
		position: absolute;
		top: 0;
		left: 2/18 * 100vw;
		right: 2/18 * 100vw;
		border-top-left-radius: 30px;
		border-top-right-radius: 30px;
		background: $new-blue;
		background: linear-gradient($new-blue, rgba($new-blue, 0));

		@include respond-to("mobile") {
			left: 20px;
			right: 20px;
		}
	}

	p {
		padding-bottom: 20px;
	}
	a {
		@extend %u-link--underline;
		color: white;

		transition: color 0.4s ease;
		&:hover {
			color: $new-pink;
		}
	}
}

.Site-foot-content {
	margin-left: 1/18 * 100vw;
	margin-right: 1/18 * 100vw;
	padding-top: 0.5/18 * 100vw;

	@include respond-to("mobile") {
		margin-left: 0;
		margin-right: 0;
	}
	//padding-top: 20px;
}

.Site-foot-logo {
	position: relative;
	width: 2/18 * 100vw;
	margin-bottom: 0.5/18 * 100vw;

	@include respond-to("mobile") {
		width: 100px;
		margin: 40px;
	}

	a {
		border: none !important;
	}
}

.Site-foot-grid {
	@include respond-to("tablet-up") {
		width: 80%;
	}

	max-width: 700px;
}
.Site-foot-nav {
	padding-top: 50px;
	@extend %u-text--mono;
	* {
		@extend %u-text--mono;
	}
	/*position: absolute;
	left: 2/18 * 100vw;
	right: 2/18 * 100vw;
	bottom: 230px;*/
	padding-top: 2/18 * 100vw;

	ul {
		margin: 0 1/18 * 100vw;
		position: relative;

		li:last-child {
			position: absolute;
			right: 0;
			top: 0;
		}
	}

	a {
		&:before {
			content: "_";
		}
		border-color: transparent;

		&:hover {
			border-color: white !important;
			color: white !important;
		}
	}
}

.Site-modal {
	background: white;
	position: fixed;
	top: 60px;
	left: 0;
	right: 0;
	bottom: 0;

	z-index: 5;
	transition: transform 0.6s ease, opacity 0.6s ease;
	pointer-events: none;
	@include margin-for-menu();
	transform: translateY(20px);
	border-top-left-radius: 30px;
	border-bottom-left-radius: 30px;
	opacity: 0;

	top: 10px;
	right: 10px;
	bottom: 10px;
	left: 10px;
	border-radius: 20px;

	@include respond-to("desktop") {
		top: 30px;
		right: 30px;
		bottom: 30px;
		border-radius: 30px;
		left: 30px;

		//box-shadow: -1px 0 4px rgba(0, 0, 0, 0.1);
	}

	.Site--modal & {
		transform: translateX(0%);
		opacity: 1;
		pointer-events: all;
	}

	.Site-modal-close {
		position: absolute;
		top: 13px;
		right: 30px;
		width: 25px;
		height: 25px;
		display: block;
		z-index: 2;
	}

	.Site-modal-body {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		@include scrollable();
	}
}

.Html--modal {
	overflow: hidden;

	.Site-template {
		opacity: 0.5;
		filter: blur(4px);
	}
}

.Site-scroll {
	height: 100vh;
	//border-top:1px dotted rgba(255,255,255,0.2);
	//border-bottom:1px solid rgba(255,255,255,0.2);
	position: relative;

	&.Site-scroll--right {
	}

	[data-grid] > div {
		vertical-align: middle;
	}

	.Site-scroll-content {
		max-width: 450px;
		margin-left: auto;
		margin-right: auto;
	}

	.Site-scroll-video {
		margin-left: 280px;
		margin-right: 40px;
		padding-bottom: 40px;
		video {
			display: block;
		}
	}

	.Site-scroll-bg {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 50%;
		opacity: 1;
		transition: opacity 0.4s ease;
		z-index: 0;
		overflow: hidden;
		video {
			height: 100%;
			width: auto;
			opacity: 0.3;
		}
	}
	.Site-scroll-text {
		padding: 20vh 10vh;
		padding-top: 20vh;
		position: sticky;
		top: 0;
		left: 0;

		z-index: 1;

		&.Site-scroll-text--flush {
			padding-top: 0;
		}

		&.Site-scroll-text--aside {
			width: 50%;
			padding-right: 40px;
		}

		&.Site-scroll-text--unsticky {
			position: relative;
		}

		&.Site-scroll-text--right {
			left: 50%;
			width: 50%;
			padding-left: 0;
			padding-left: 5vw;
		}

		h1 {
			color: white;
			font-size: 5vw;
			padding-bottom: 50px;
			//text-shadow:3px 3px #060626;
		}

		h3 {
			color: rgb(180, 255, 255);
			color: rgb(69, 45, 118);
			color: rgb(100, 70, 150);
			@include font-size(15px);
			letter-spacing: 0.05em;
			padding-bottom: 15px;
		}

		p {
			color: white;
			padding-bottom: 30px;
			max-width: 600px;
		}

		.Image {
			box-shadow: 0 0 100px rgba(128, 40, 211, 0.15);
		}
	}

	&.Site-scroll--unsticky {
		height: auto;
		min-height: 100vh;

		.Site-scroll-text {
			position: relative;
		}
	}

	&.Site-scroll--alt {
		background: rgba(13, 10, 50, 1);
		box-shadow: 0 0 3px rgba(0, 0, 0, 0.7);
		/*&:after{
		content:'';
		background:rgb(13,10,50);
		display:block;
		width:100%;
		height:100%;
		z-index:-1;
		position:absolute;
		top:0;
		left:0;
	}*/
	}

	&.Site-scroll--auto {
		height: auto;
	}

	&.Site-scroll--long {
		height: 150vh;
	}

	&.Site-scroll--natural {
		height: auto;

		.Site-scroll-text {
			position: relative;
			padding-top: 10vh;
		}
	}
}
.Site-canvas {
	pointer-events: none;
	z-index: 0;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: 100vh;
	opacity: 1;
	//transition: opacity 1s ease, transform 5s 1s $easeInOutCubic;
	//transform: translate3d(0, 0, 0);

	.Html--loadComplete & {
		//opacity: 0.65;
		//transform: translate3d(0, 20%, 0);
	}

	& > * {
		width: 100%;
		height: 100%;
	}
}

@keyframes colors {
	0% {
		fill: #ff4299;
	}
	50% {
		fill: #232299;
	}
	/*75%{
		fill:#f8f382;
	}*/
	100% {
		fill: #ff4299;
	}
}

@keyframes white {
	0% {
		fill: #000;
	}
	100% {
		fill: white;
	}
}

@keyframes grey {
	0% {
		fill: #000;
	}

	100% {
		fill: #999;
	}
}

canvas#sketch {
	display: none;
}
.Site-scrollDown {
	display: block;
	position: fixed;
	z-index: 4;
	left: 50%;
	transform: translate(-50%, 0%);
	bottom: 0;
	color: white;
	opacity: 1;
	padding: 20px;
	cursor: pointer;
	text-align: center;
	transition: transform 0.4s ease, opacity 0.4s ease;

	display: none;

	.Site--scrolled & {
		transform: translate(-50%, 30px);
		opacity: 0;
	}
}
.Site-message {
	position: fixed;
	top: 40%;
	left: 10%;
	transform: translate3d(-0%, -10%, 0);
	font-size: 4vw;
	color: white;
	z-index: 2;
	text-align: left;
	line-height: 120%;
	transition: transform 0.7s ease, opacity 0.7s ease;
	pointer-events: none;
	opacity: 0;
	display: none !important;
	&.Site-message--logo {
		transform: translate3d(-50%, -50%, 0);

		svg {
			$w: 40vw;
			width: $w;
			height: $w * 0.24;

			.st1 {
				animation: colors 2s ease infinite;

				&.s1 {
					animation: colors 2s 0s ease infinite;
				}
				&.s2 {
					animation: colors 2s 0.1s ease infinite;
				}
				&.s3 {
					animation: colors 2s 0.2s ease infinite;
				}
				&.s4 {
					animation: colors 2s 0.3s ease infinite;
				}
				&.s5 {
					animation: colors 2s 0.4s ease infinite;
				}
			}
		}
	}
}

.Site--message1 {
	.Site-message--1 {
		transform: translate3d(0%, -50%, 0);
		opacity: 1;
	}
}

.Site--message2 {
	.Site-message--2 {
		transform: translate3d(-50%, -50%, 0);
		opacity: 1;
	}
}

.Site--messageLogo {
	.Site-message--logo {
		transform: translate3d(-50%, -50%, 0);
		opacity: 1;
	}
}

.Site--messageMono {
	.Site-message.Site-message--logo {
		svg {
			.v {
				animation: none;
				fill: white;
				//animation:white 2s ease forwards!important;
			}

			.c {
				animation: none;
				fill: #999;
				//animation:grey 2s ease forwards!important;
			}
		}
	}
}

.Site {
	.Site-shortcuts {
		position: absolute;
		top: 30px;
		right: 90px;
		z-index: 3;

		@include respond-to("mobile") {
			right: 0px;
			top: 15px;
			.List {
				text-align: right;

				li {
					padding-right: 10px;
				}
				a {
					margin: 0 !important;
				}

				.Link--button {
					padding-right: 20px;
					&:after {
						display: none;
					}
				}
			}
		}
	}

	.Site-fixed-menu {
		position: fixed;
		bottom: 60px;
		background: white;
		box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
		border-radius: 10px;
		padding: 30px 40px;
		padding-right: 50px;
		left: 50%;
		transform: translate(-50%, 20px);
		z-index: 5;
		ul {
			margin: 0;
			white-space: nowrap;
			transform: translateY(6px);
		}
		li {
			position: relative;
			padding-left: 0;
			padding-right: 40px;
			font-size: 14px;

			&:last-child {
				padding-right: 0;
			}

			span {
				font-size: 10px;
				font-family: "DM Mono";
				display: inline-block;
				margin-right: 5px;
				transform: translateY(-120%);
			}
		}

		opacity: 0;
		transition: opacity 0.4s ease, transform 0.4s ease;
		.Html--scrolled & {
			opacity: 1;
			transform: translate(-50%, 0);
		}
		.Html--footerVisible & {
			opacity: 0;
			transform: translate(-50%, -20px);
		}

		display: none;
	}
	.Site-images {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 0;
		opacity: 0.1;
		filter: blur(3px);
		transition: opacity 1s ease, filter 1s ease;

		& > * {
			@extend %u-box--fluid;

			& > * {
				@extend %u-box--fluid;
			}
		}
	}

	&.Site--projects {
		.Site-images {
			opacity: 1;
			filter: blur(0px);
		}
	}
	.Site-content {
		position: relative;
		z-index: 1;
	}
	.Site-template {
		[data-rel="transition"] {
			height: auto !important;
		}
	}

	&.Site--menu {
	}

	.Site-logo {
		position: absolute;
		transition: top 0.4s ease, left 0.4s ease;
		width: 100px;
		top: 25px;
		left: 15px;

		a {
			display: block;
			text-indent: 101%;
		}

		@include respond-to("desktop") {
			right: 35px;
			top: 35px;
			left: 35px;
		}
	}

	.Site-nav {
		padding-top: 40px;

		position: fixed;
		top: 0;
		right: 0;
		width: 100%;

		pointer-events: none;

		z-index: 5;
		a {
			pointer-events: all;
		}

		.List {
			padding: 5px 0;
			text-align: right;
			li:last-child {
				padding-right: 0;
			}
		}
		@include respond-to(mobile) {
		}
	}

	.Site-map,
	.Site-contact {
		display: none;
	}

	.Site-template {
		transition: opacity 0.4s ease, filter 0.4s ease;
		overflow: visible;
	}

	&.Site--loading {
		.Site-template {
			opacity: 0;
			//transform:translateY(10px);
		}
	}
}

.Site--menu {
	.Site-logo {
		position: fixed;
		top: 60px;
		left: 60px;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

.Site-welcome {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;
	opacity: 0;
	//transition:opacity 1s ease;
	animation: fadeIn 1s ease forwards;
	pointer-events: none;
}

.Site-page {
}

.Site-splash {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9;

	background: white;
	transition: opacity 1s 0.5s ease;
	cursor: pointer;
}

.Site--preloaded {
	.Site-welcome {
		opacity: 1;
		animation: fadeOut 1s 0s ease forwards;
	}
}

.Site--open {
	.Site-splash,
	.Site-welcome {
		pointer-events: none;
		opacity: 0;
	}
}

.Html--project {
	overflow: hidden;
}

.Site-popup {
	position: fixed;
	z-index: 99999;
	bottom: 20px;
	right: 20px;
	background: rgba(0, 0, 0, 0.5);
	background: $purple;
	border-radius: 5px;
	padding: 15px;
	width: 420px;
	transition: opacity 0.4s ease, transform 0.4s ease;
	box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
	padding-top: 40px;

	.Html--seenpopup & {
		opacity: 0;
		pointer-events: none;
		transform: translateY(2%);
	}

	.Site-popup-close {
		position: absolute;
		top: 10px;
		left: 10px;
		transform-origin: 50% 50%;
		transform: rotate(-45deg);
		cursor: pointer;
		padding: 0;
		margin: 0;
		width: 20px;
		height: 20px;
		color: white;
		border: 1px solid white;
		opacity: 0.5;
		font-size: 18px !important;
		line-height: 18px;
		border-radius: 50%;
		text-align: center;
	}
	@include respond-to("mobile") {
		border-radius: 0;
		right: 0;
		bottom: 0;
		left: 0;
		width: auto;
	}
	.Site-popup-sections {
		position: relative;
	}
	.Site-popup-section {
		position: relative;
		display: inline-block;
		width: 30%;
		vertical-align: bottom;
		//padding-right:5px;
	}
	.Site-popup-section--text {
		width: 70%;
		padding-right: 40px;
	}
	.Site-popup-cover {
		border-radius: 5px;
		box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
		transform-origin: 0 100%;
		position: absolute;
		bottom: 0;
		right: 0;
	}

	.Site-popup-cover--front {
		position: absolute;
		bottom: 0;
		right: 0;
		transform: rotate(-5deg);
	}

	.Site-popup-cover--back {
		transform: rotate(5deg);
	}
}

.Site-player {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 9999;
	background: rgba($new-light, 0.3);
	backdrop-filter: blur(30px);

	pointer-events: none;
	opacity: 0;
	transition: opacity 0.4s ease;
	display: none;

	.Html--player & {
		opacity: 1;
		pointer-events: all;
		display: block;
		.Site-player-body {
			opacity: 1;
			transform: translate(-50%, -50%);
		}
	}

	.Site-player-back {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: block;
		cursor: alias;
	}
	.Site-player-body {
		width: 95%;
		display: block;
		height: auto;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 3;
	}

	.Site-player-close {
		position: absolute;
		top: 20px;
		left: 50%;
		transform: translateX(-50%);
		z-index: 4;

		.Site-icon {
			background: white;
			border: none;
			box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
		}
	}

	video {
		width: 100%;
		display: block;
		height: auto;
		border-radius: 10px;
		//box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
	}
}
