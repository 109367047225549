.Form--newsletter{
	position:relative;
	border:1px solid white;
	padding-right:40px;
	max-width:500px;

	input{
		background:transparent;
		color:white;
		line-height: 40px;
		height:40px;
		padding-left:10px;
		display:block;
		width:100%;
		border-right:1px solid white;
	}

	.Form-field-title{
		position:absolute;
		bottom:100%;
		left:0;
		padding:10px 0;
		@extend %u-text--subheading;
		opacity:0.3;
	}
	.Form-submit{
		position:absolute;
		width:40px;
		height:40px;

		top:0;
		right:0;

		button{
			position:absolute;
			top:0;
			left:0;
			height:100%;
			width:100%;
			padding:0;
			padding-top:40px;
		
			overflow:hidden;

			&:after{
				@extend %u-iconfont;
				@extend %u-iconfont--arrow-right;
				display:block;
				position:absolute;
				top:50%;
				left:50%;
				transform:translate(-50%,-50%);
				font-size:20px;
			}
		}
	}
}