%u-list--dashed{

	padding-bottom:1em;
	li{
		position:relative;
		padding-left:1.5em;
		padding-bottom:0;
		margin-bottom:0;
		&:before{
			content:'—';
			display:inline-block;
			position:absolute;
			top:0em;
			left:0;
		}

		em{
			@extend %u-text--small;
			color:$skyBlue;
			font-style: normal;
		}
	}

}

%u-list--inline{

	li{
		display:inline-block;
		vertical-align:top;
		padding-right:15px;
	}

}