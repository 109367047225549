.Template {
	padding-top: 70px;
	@include respond-to("desktop") {
		padding-top: 10vh;
	}

	padding-bottom: 100px;

	.Site-modal & {
		padding-top: 40px;
		@include respond-to("mobile") {
			padding-top: 20px;
		}
	}
}

.Template-head {
	width: 80%;
	max-width: 1200px;
	padding-top: 50px; // Match blocks heading
}

.Template-filter-section {
	padding: 40px;
	padding-top: 20px;

	.Content {
		border-bottom: 1px solid rgba($new-dark, 0.2);
		padding-bottom: 8px;
	}

	@include respond-to("mobile") {
		padding: 20px 0;
		padding-bottom: 0;

		.Content {
			margin-left: 10px;
			margin-right: 10px;
		}
	}
}

.Template-filters {
	padding: 0 80px;
	padding-top: 40px;
	position: relative;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-evenly;
	row-gap: 20px;
	& > * {
		flex: 0 calc(33% - 20px);

		@include respond-to("mobile") {
			flex: 0 calc(33% - (((5 * 2) / 3) * 1px));
		}
	}

	&[data-cols="4"] {
		& > * {
			flex: 0 calc(25% - 20px);

			@include respond-to("mobile") {
				flex: 0 calc(50% - (((5 * 1) / 2) * 1px));
			}
		}
	}

	@include respond-to("mobile") {
		padding: 0px;
		width: 100%;
		gap: 5px;
		padding: 10px;
	}
}

.Template-intro {
	.Container > * {
		width: 50%;
		margin-left: auto;
		@include respond-to("desktop") {
			width: 40%;
		}

		@include respond-to("mobile") {
			width: 80%;
		}
	}
}

.Template--homepage,
.Template--blocks {
	.Template-head {
		display: none;
	}

	//padding-top:40px;
}

.Template--listing {
	//.Site-modal & {
	.Template-head,
	.Template-intro {
		display: none;
	}
	.Block-text {
		display: none;
	}
	.Block-items {
		padding-top: 0 !important;
	}
	//}
	.Dropdown {
		@include respond-to("mobile") {
			margin-top: 40px;
		}
	}
}

.Template--locations {
	.Template-body {
		@include respond-to("mobile") {
			.Block .Grid--article .Grid-item {
				padding-left: 0;
			}
		}
	}
}

.Template-media--portrait {
	.Item {
		width: 60%;
		max-width: 800px;
		margin-right: auto;
	}
}

.Template--blog,
.Template--article {
	@include margin-for-menu();
	//background:$grey;

	.Blocks-section--alt {
		//background:darken($grey,5%);
	}

	.Site-modal & {
		margin-left: 0;
	}
}
