@keyframes load{

	0%{

		opacity:1;
		width:0%;
		

	}

	50%{

		width:100%;
		opacity:1;
		height:5px;
	}

	80%{

		opacity:1;
		height:5px;
		width:100%;

	}

	100%{

		opacity:1;
		height:0;
		width:100%;

	}

}
.Loader{
	@include hide-text;
	position:fixed;
	bottom:0;
	left:0;
	background:$pink;
	width:0;
	height:5px;
	z-index:5;
	animation: load 2s forwards;
	display:block;
	background: rgb(174,0,155); /* Old browsers */
background: -moz-linear-gradient(45deg, rgba(174,0,155,1) 0%, rgba(130,37,206,1) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(45deg, rgba(174,0,155,1) 0%,rgba(130,37,206,1) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(45deg, rgba(174,0,155,1) 0%,rgba(130,37,206,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ae009b', endColorstr='#8225ce',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

}