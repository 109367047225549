/**
 * Settings
 *
 * Core defaults
 */

/**
 * Breakpoints
 *
 * Hold our breakpoint aliases and conditions in a list. These can be invoked
 * later on via the `respond-to()` mixin found in _mixins.scss.
 */

$breakpoints: (
  "mobile" "(max-width: 745px)",
  "tablet" "(min-width: 746px) and (max-width: 1023px)",
  "tablet-up" "(min-width: 746px)",
  "desktop" "(min-width: 1024px)",
  "desktop-plus" "(min-width: 1200px)",
  "wide" "(min-width: 1400px)",
  "shallow" "(min-width: 1024px) and (max-height: 850px)",
  "iphone5"
    "(min-width: 320px) and (max-width: 568px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 40/71) and (orientation: portrait)"
);

/**
 * Base Grid & Spacing values
 */

$base-vw: 1024;
$base-vh: 768;
$base-padding: (
  "*": 40px,
  "mobile": 20px,
);
$base-grid-gutter: $base-padding;
$base-max-width: "none";
$base-max-text-width: 700px;
$base-max-dialog-width: 700px;

/**
 * Base Type Settings
 */

$base-font-size: 18px;
$base-line-height: 24px;
$base-letter-spacing: 0;

$base-font-family: "Favorit-Light";
$base-font-family-bold: "Favorit-Light";
$base-font-family-alt: "Favorit-Light";
$base-text-align: left;

/**
 * Palette
 */

// Gradient
$pink: rgb(174, 0, 155);
$purple: rgb(130, 37, 206);
$glow: rgba(128, 40, 211, 0.15);

// Gradient
$turquoise: rgb(161, 255, 255);
$skyBlue: rgb(123, 191, 217);
$midBlue: rgb(69, 45, 118);

// Sections
$darkBlue: rgb(6, 6, 38);
$altBlue: rgb(13, 10, 50);

// Text
$highlight: rgb(100, 70, 150);

// Modals
$grey: rgb(155, 155, 183);

// 2022 palette
$new-dark: #300f76;
$new-light: #f4eff2;
$new-pink: #d695ff;
$new-purple: #8028d2;
$new-blue: #2b1571;
$new-purple-tint: #fbf4ff;

/**
 * Base Colours
 */

$base-color: $new-dark;
$base-background: $new-light;
$base-link-color: $new-dark;
$base-link-opacity-hover: 1;
$base-active-color: $new-dark;

$base-overlay-background: $new-dark;
$base-overlay-transparency: 0.85;

$base-ui-color: #333;
$base-ui-color-alt: #000;

$base-placeholder-color: #787878;
$base-placeholder-opacity: 0.3;

$base-selection-background: $new-dark;
$base-selection-color: #fff;

$base-image-color: #eee;
$base-image-color-loaded: #eee;
$base-image-fade: 2s;
$base-image-delay: 0.25s;
$base-image-ease: ease;

/**
 * Base Icons
 */

$base-icons: "map-marker";
$base-icon-size: 24px;
$base-icon-extension: "svg";

$base-icon-glyphs: (
  "arrow-down": "\e900",
  "arrow-left": "\e901",
  "arrow-right": "\e902",
  "arrow-up": "\e903",
  "facebook": "\e904",
  "high": "\e905",
  "instagram": "\e906",
  "linkedin": "\e907",
  "low": "\e908",
  "play": "\e909",
  "twitter": "\e90a",
  "vimeo": "\e90b",
);

/**
 * Base Cursors
 */

$base-cursor-prev: pointer;
$base-cursor-next: pointer;
$base-cursor-play: pointer;
$base-cursor-pause: pointer;

/**
 * Base Sections (Sections being regions that allow you to control core styling)
 * The keys in this array will be used as modifiers (.Section--{key}) with the config settings provided
 * At a config level Sections can define:
  - background color
  - foreground color,
  - heading typeface,
  - body typeface
  - padding for the .Section-body
  - max width for the .Section-body
  - colour for images
  - colour links
  - colour for active items
 */

$base-sections: (
  "*": (
    "transition": all 0.4s ease,
    "background": transparent,
    "color": $base-color,
    "heading-font-family": $base-font-family,
    "text-font-family": $base-font-family,
    "container-padding": $base-padding,
    "container-max-width": $base-max-width,
    "image-color": $base-image-color,
    "link-color": $base-link-color,
    "active-color": $base-active-color,
  ),
  "modifier": (
    "background": $base-color,
    "color": $base-background,
    "heading-font-family": $base-font-family-alt,
    "text-font-family": $base-font-family,
    "container-padding": $base-padding,
    "container-max-width": $base-max-width,
    "image-color": $base-background,
    "link-color": $base-ui-color,
    "active-color": $base-active-color,
    "when-inside": (
      ".Site--modified": (
        "background": $base-color,
        "color": $base-background,
      ),
    ),
  ),
);

/**
 * Top level hooks
 */

$base-touch-class: ".Site--touch";
