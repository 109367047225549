.Block {
	padding: 20px;
	@include pad-for-menu();
	@include respond-to("desktop") {
		padding-right: 40px;

		.Site-modal & {
			padding-left: 40px;
		}
	}
	padding-top: 20px;
	padding-bottom: 20px;

	@include respond-to("mobile") {
		padding-top: 20px;
		padding-bottom: 20px;
	}

	.Template--blog &,
	.Template--article &,
	.Blocks--flush &,
	.Blocks-section--alt & {
		@include respond-to("desktop") {
			padding-left: 40px;
		}
	}

	.Blocks--flush & {
		padding-top: 0;
	}
}

.Block--dotmap {
	.Block-content {
		padding: 1/18 * 100vw;
		padding-top: 0;
	}

	.Block-list {
		border-top: 1px solid $new-blue;
		padding-top: 20px;
		position: relative;

		.List--explore {
			position: absolute;
			top: 20px;
			left: 0;

			@include respond-to("mobile") {
				position: relative;
				top: auto;
			}
		}
	}

	.Block-map {
		margin-left: 4/18 * 100vw;
		img {
			display: block;
			width: 100%;
			//transform: translate(1/18 * 100vw, 1/18 * 100vw);
		}
	}
}
.Block--logos {
	padding: 2/18 * 100vw;
	padding-bottom: 2/18 * 100vw;
	padding-left: 3/18 * 100vw;
	padding-right: 3/18 * 100vw;

	@include respond-to("mobile") {
		padding-left: 1/18 * 100vw;
		padding-right: 2/18 * 100vw;
	}

	img {
		display: block;
		width: 100%;
	}

	.Block-logos {
		padding-top: 40px;
	}
}

.Block--contacts {
	padding-top: 1/18 * 100vw;
	.Block-sections {
		margin: 1/18 * 100vw;
		margin-right: 2/18 * 100vw;

		position: relative;
	}
	.Block-section {
		border-top: 1px solid $new-blue;
		margin-bottom: 20px;

		&:first-child {
			margin-bottom: 40px;
		}

		& > .Content {
			padding-left: 0;
			padding-top: 5px;
			padding-bottom: 40px;
		}
	}

	.Block-section--hasOne {
		@include respond-to("tablet-up") {
			padding-left: 10px;
			border-top: none;
			&:before {
				display: block;
				content: "";
				border-top: 1px solid $new-blue;
			}
			display: inline-block;
			width: 50%;
			vertical-align: top;
		}
	}

	.List--inline {
		padding-left: 1/18 * 100vw;
		padding-top: 10px;
	}

	.List--contacts {
		li {
			padding-bottom: 40px;
		}
	}

	.Block-section--hasMany {
		.List--contacts {
			li {
				@include respond-to("tablet-up") {
					display: inline-block;
					width: 50%;
					vertical-align: top;
				}
			}
		}
	}

	dl {
		li {
			list-style: none;
			position: relative;
			min-height: 50px;

			dt {
				position: absolute;
				top: 0;
				left: 0;
				transform: translate(0, -25%);

				.Site-icon {
					border: none;
				}

				@include respond-to("mobile") {
					left: 10px;
				}
			}

			dd {
				@include respond-to("mobile") {
					padding-left: 40px;
				}
			}
		}
	}
}

.Block--calculator {
	padding-left: 0 !important;
	padding-right: 0 !important;
}
.Block--image.Block--half {
	display: inline-block;
	vertical-align: top;
	width: 50%;
}

.Block--folio {
	padding: 50px 0;
}

.Block--reel {
	padding-top: 1/18 * 100vw;
	padding-bottom: 1/18 * 100vw;
	.Block-content {
		position: relative;
		margin: 0 2/18 * 100vw;

		@include respond-to("mobile") {
			margin: 0 20px;
		}
	}
}

.Block--dotAnimation {
	position: relative;
	padding-top: 10vh;

	@include respond-to("mobile") {
		padding-top: 70vh;
		[data-progress-marker] {
			width: 100px;
			height: 100px;
			background: transparent;
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	.Block-marker {
		position: absolute;
		width: 100px;
		height: 100px;
		background: transparent;
		top: 0;
		left: 0;
	}
	.Block-heading {
		width: 100%;
	}
	.Block-content {
		position: relative;
	}
	.Block-content,
	.Block-items {
		width: 60%;
		margin-right: auto;
	}

	.Block-items {
		margin-left: auto;
		margin-right: 0;
		padding-top: 50px;
	}

	@include respond-to("desktop") {
		.Block-heading {
			padding-right: 100px;
		}
		.Block-content,
		.Block-items {
			width: 50%;
		}
	}

	@include respond-to("mobile") {
		.Block-heading {
			padding-right: 20px;
		}
		.Block-content,
		.Block-items {
			width: 100%;
		}
	}

	&.Block--imageLeft {
		.Block-content {
			margin-left: auto;
			margin-right: 2/18 * 100vw;
		}
		.Block-items {
			margin-left: 0;
			margin-right: 1/18 * 100vw;
		}
	}
}

.Block--section {
	.Block-items {
		padding-top: 50px;
	}
	@include respond-to("desktop") {
		&.Block--itemsRight {
			.Block-items {
				width: 70%;
				margin-left: auto;
			}
		}

		&.Block--itemsLeft {
			.Block-text {
				width: 50%;
				margin-left: auto;
				padding-right: 0;
			}

			.Block-heading {
				width: auto;
				padding-right: 0;
			}

			.Block-items {
				width: 70%;
				margin-right: auto;
			}
		}
	}
}

.Block-heading {
	@extend %u-text--title;

	padding-right: 50px;
	@include respond-to("desktop") {
		padding-right: 30vw;
	}

	padding-bottom: 50px;

	.Block--videoTeaser & {
		padding-right: 0;

		@include respond-to("mobile") {
			padding-right: 20px;
			max-width: 300px;
		}
		&:after {
			display: inline-block;
			@extend %u-iconfont;
			@extend %u-iconfont--play;
			color: $purple;
			margin: 10px;
			transform: translateY(0.5vw);
		}
	}
}

.Block--videoTeaser {
	min-height: 50vh;
}

.Blocks-section {
	padding-top: 20px;
	&:first-child {
		padding-top: 0;
	}
}
.Blocks-section--alt {
	.Block {
		padding-right: 0;
	}

	padding: 60px 0;
	background: white;
	border-top-left-radius: 30px;
	border-bottom-left-radius: 30px;
	margin-left: 2/18 * 100vw;

	@include respond-to("mobile") {
		margin-left: 10px;
	}
}

.Block--text {
	.Content {
		padding-left: 1/18 * 100vw;
		h1 {
			margin-left: 1/18 * -100vw;
		}
	}

	.Grid--links {
		padding-left: 1/18 * 100vw;
	}
}

.Block--swiper {
	.swiper {
		overflow-y: visible;
		overflow-x: hidden;
		padding-top: 50px;
		padding-bottom: 50px;
	}

	.swiper-nav {
		width: 60px;
		height: 60px;
		background: rgba(255, 255, 255, 0.2);
		backdrop-filter: blur(10px);
		position: absolute;
		top: 50%;
		transform: translateY(-100%);
		z-index: 5;
		border-radius: 50%;
		cursor: pointer;
		&:after {
			content: "";
			font-family: linearicons;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			color: white;
		}

		@include respond-to("mobile") {
			width: 40px;
			height: 40px;
		}
	}

	.swiper-nav--next {
		right: 40px;
		&:after {
			content: "arrow-right";
		}

		@include respond-to("mobile") {
			right: 10px;
		}
	}

	.swiper-nav--prev {
		left: 40px;
		&:after {
			content: "arrow-left";
		}

		@include respond-to("mobile") {
			left: 10px;
		}
	}
	.swiper-slide {
		.Image {
			opacity: 0.7;
			transition: opacity 0.5s ease, transform 0.5s ease;
		}
		&.swiper-slide-prev,
		&.swiper-slide-next {
			.Image {
				opacity: 1;
			}
		}
		&.swiper-slide-active {
			.Image {
				opacity: 1;
			}
		}
	}
}

.Block--carousel {
	overflow: hidden;
	max-width: 100vw;
}

.Block--newsletter {
	.Block-items {
		//padding-top:10px;
	}

	@include respond-to("desktop") {
		&.Block--itemsRight {
			.Block-text {
				width: 50%;
				margin-left: auto;
				padding-right: 0;
			}

			.Block-heading {
				width: auto;
				padding-right: 0;
			}

			.Block-items {
				width: 50%;
				margin-left: auto;
				padding-right: 10%;
			}
		}

		&.Block--itemsLeft {
			.Block-text {
				width: 50%;
				margin-left: 0;
				margin-right: auto;
				padding-right: 0;
			}

			.Block-heading {
				width: auto;
				padding-right: 0;
			}

			.Block-items {
				width: 50%;
				margin-right: auto;
				padding-right: 10%;
			}
		}
	}
}

.Block--map {
	height: 70vw; //Will be overridden by JS
	overflow: hidden;
	position: relative;
	margin-bottom: 50px;

	@include respond-to("desktop") {
		height: 500px;
	}

	&.Block--half {
		@include respond-to("desktop") {
			width: 50%;
		}
	}

	.Block-content {
		@extend %u-box--fluid;
		height: 100%;
		min-height: 45vh;

		.MiniMap {
			z-index: 1;
			position: absolute;
		}

		& > * {
			@extend %u-box--fluid;
		}
	}
}

.Block--carousel {
	.slider {
		.slick-arrow {
			position: absolute;
			top: 100%;
			right: 0;
			transform: translateY(-50%);

			@include respond-to("mobile") {
				left: 0;
			}

			z-index: 2;
			background: transparent;
			&:focus {
				outline: none;
			}
			cursor: pointer;
			background-position: center center;
			background-repeat: no-repeat;
			width: 40px;
			height: 40px;

			&:after {
				display: block;
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0%;
				left: 50%;
				transform: translate(-50%, 0%);
				color: rgba(255, 255, 255, 0.5);
			}

			padding-top: 40px;
			overflow: hidden;

			&.slick-prev {
				right: 40px;
				&:after {
					@extend %u-iconfont;
					@extend %u-iconfont--arrow-left;
					display: block;
					height: 20px;

					font-size: 25px;
				}
			}

			&.slick-next {
				right: 0;
				&:after {
					@extend %u-iconfont;
					@extend %u-iconfont--arrow-right;
					display: block;
					height: 20px;

					font-size: 25px;
				}
			}
		} // End Arrow

		.slick-dots {
			margin: 0 auto;
			position: relative;
			width: auto;
			text-align: left;

			li {
				display: inline-block;
				cursor: pointer;
				width: 10px;
				height: 10px;
				border-radius: 50%;
				background: transparent;
				border: 1px solid rgba(255, 255, 255, 0.5);
				transition: background 0.5s ease, border 0.5s ease;
				margin: 3px;
				position: relative;
				button {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					opacity: 0;
				}

				&.slick-active {
					background: rgba(255, 255, 255, 0.5);
					border: 1px solid rgba(255, 255, 255, 0.5);
				}
				@include respond-to("mobile") {
					width: 10px;
					height: 10px;
					margin: 3px;
				}
			}
		} // End Dots
	} // End Slider
}
