.Reel {
	position: relative;

	a {
		display: block;
		opacity: 0 !important;
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		z-index: 3;
		background: red;
	}

	&:after {
		content: "";
		position: absolute;
		background: url(/ui/img/play.svg) 52% center no-repeat;
		background-color: rgba(255, 255, 255, 0.2);

		backdrop-filter: blur(10px);
		width: 80px;
		height: 80px;
		border-radius: 50%;
		bottom: 40px;
		left: 40px;
		//transform: translate(-50%, -50%);
		transition: transform 0.6s ease;
	}

	cursor: pointer;
	&:hover {
		&:after {
			transform: scale(1.2);
		}
	}
}

.Reel-teaser {
	video {
		border-radius: 10px;
		display: block;
		width: 100%;
		height: auto;
		//box-shadow: 0 0 100px rgba($new-dark, 0.3);
	}
}
