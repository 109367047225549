.Image {
	background: rgb(100, 70, 150);
	border-radius: 10px;
	overflow: hidden;
}
.Image-media {
	opacity: 0;
	transition: opacity 0.4s 0.2s ease;
}
.Image-media[style],
.Image-media[src] {
	//animation:fadeIn 1s 0.1s forwards;
	backface-visibility: hidden;
	opacity: 1;
}
