.Link--callToActionOld {
	display: block;
	background: $base-color;
	color: $base-background;
	padding: 5px;

	&:after {
		content: "\02192";
		display: inline-block;
		margin-left: 5px;
		transition: margin 0.4s ease;
	}

	&:hover {
		&:after {
			margin-left: 10px;
		}
	}
}

.Link--callToAction,
.Link--button {
	@extend %u-link--button;
}

.Link--radio {
	display: block;
	padding: 8px;
	@extend %u-text--small;
	cursor: pointer;
	position: relative;
	transition: opacity 0.4s background 0.4s ease;

	&:hover {
		background: rgba(255, 255, 255, 0.1);
	}

	opacity: 0.5;

	&.is-active {
		opacity: 1;
	}

	&:after,
	&:before {
		display: block;
		content: "";
		width: 10px;
		height: 10px;
		border-radius: 50%;
		position: absolute;
		top: 50%;
		margin-top: -5px;
		right: 5px;
	}

	&:after {
		border: 1px solid $purple;
	}
	&:before {
		background: $purple;
		transform: scale(0, 0);
	}

	&.is-active {
		&:before {
			transform: scale(1, 1);
		}
	}
}

.Link--primary {
	background: $new-dark;
	color: white;
}

.Link--blur {
	@extend %u-link--button;
}
