.Logo {
	display: block;
	@include pad-ratio(1, 0.25);
	height: 0;
	overflow: hidden;

	&:after,
	&:before {
		pointer-events: none;
		transition: opacity 0.4s ease;
		content: "";
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background: url(/ui/img/logo.svg);
		background-size: 100% auto;
		background-repeat: no-repeat;
		background-position: center center;
	}

	&:after {
		opacity: 0;
		background-image: url(/ui/img/logo-white.svg);
	}

	.Site--menu & {
		&:after {
			opacity: 1;
		}
		&:before {
			opacity: 0;
		}
	}

	&.Logo--alt {
		&:after {
			opacity: 1;
		}
		&:before {
			opacity: 0;
		}
	}
}
