.Item--lozenge {
	background: rgba($new-pink, 0.1);
	border-radius: 10px;
	padding: 10px;
	justify-content: space-evenly;
	& > *:first-child {
		flex-grow: 2;
	}

	& > *:last-child {
		flex-grow: 0;
		flex-shrink: 0;
		width: 45px;

		@include respond-to("mobile") {
			width: 35px;
		}
	}

	transition: opacity 0.4s ease;

	&[data-active="inactive"] {
		opacity: 0.2;
	}

	cursor: pointer;
}

.Item--swiper {
	.Item-icon {
		position: absolute;
		bottom: -5px;
		right: 10px;
	}

	.Item-body {
		padding-left: 5px;
	}
}

.Item--calculator {
	& > .Item-head {
		border-bottom: 1px solid rgba($new-dark, 0.2);
		padding: 0 40px;
		padding-left: 1/18 * 100vw;
	}

	& > .Item-body {
		padding: 40px;
		padding-right: 1/18 * 100vw;
		padding-left: 1/18 * 100vw;

		@include respond-to("mobile") {
			padding: 15px;
		}
	}

	.Item-sections {
		position: relative;

		& > * {
			display: inline-block;
			vertical-align: top;
			width: 50%;

			@include respond-to("mobile") {
				width: 100%;
				padding-bottom: 30px;

				&:last-child {
					padding-bottom: 0;
				}
			}
		}
	}

	.Item-section--slider {
		padding-right: 1/18 * 100vw;
	}

	.Item--slider {
		padding-bottom: 80px;

		@include respond-to("mobile") {
			padding-bottom: 40px;
		}
	}
}
.Item--checkbox,
.Item--select {
	background: rgba($new-pink, 0.1);
	border-radius: 10px;
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-evenly;
	gap: 10px;
	padding: 10px 20px;
	transition: border 0.2s ease;

	.Item-label {
		flex-grow: 2;
		@extend %u-text--mono;
	}

	border: 1px solid transparent;
	cursor: pointer;

	&:hover {
		border-color: rgba($new-dark, 0.2);
	}
	.Item-check {
		position: relative;
		width: 25px;
		height: 25px;
		border: 1px solid;
		border-radius: 5px;

		& > * {
			transition: opacity 0.2s ease;
			opacity: 0;
		}
	}

	&[data-checked] {
		border-color: rgba($new-dark, 0.5);
		& > .Item-check > * {
			opacity: 1;
		}
	}

	&[data-variant="secondary"] {
		background: transparent;
		border-radius: 0;
		border-bottom: 1px solid rgba($new-dark, 0.5);
		justify-content: flex-start;
		&:hover {
			border-color: transparent;
			border-bottom: 1px solid rgba($new-dark, 0.5);
		}

		&[data-checked] {
			border-top-color: transparent;
			border-right-color: transparent;
			border-left-color: transparent;
		}

		.Item-label {
			@extend %u-text--subheading;
			padding: 2px 5px;
			display: inline-block;
			background: $new-pink;
			flex-grow: 0;
		}
		.Item-check {
			transform: scale(0.7);
			//background: white;
		}
		padding-left: 5px;
	}

	&[data-variant="tertiary"] {
		background: transparent;
		padding: 5px;
		padding-left: 10px;
		gap: 2px;

		.Item-check {
			transform: scale(0.5);
		}

		.Item-label {
			@extend %u-text--small;
			text-transform: none;
		}
	}

	.Item-sub-options {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: center;
		justify-content: flex-start;
		align-content: flex-start;
		gap: 2px;
		padding-top: 10px;
		padding-bottom: 10px;
		& > * {
			flex: 0 calc(50% - 10px);
		}
	}

	@include respond-to("mobile") {
		padding: 8px 5px;
		gap: 5px;
		.Item-check {
			width: 15px;
			height: 15px;
			flex-shrink: 0;
			flex-grow: 0;
			border-radius: 4px;
			padding-right: 3px;
			.Site-icon {
				width: 15px;
				height: 15px;
				position: absolute;
				top: 0;
				left: 0;

				&:after {
					font-size: 11px;
				}
			}
		}

		.Item-label {
			word-wrap: break-word;
		}

		.Item-icon,
		.Site-icon {
			width: 25px;
			height: 25px;

			&:after {
				font-size: 14px;
			}
		}

		.Site-icon {
			border: none;
		}
	}
}

.Item--select {
	padding: 20px;
	padding-right: 15px;
	position: relative;
	.Item-icon {
		width: 25px;
		height: 25px;
		transition: transform 0.2s ease;
	}
	.Site-icon {
		border-color: transparent;
		width: 25px;
		height: 25px;
		&:after {
			font-size: 15px;
		}
	}
	.Item-body {
		position: absolute;
		width: 170%;
		top: 100%;
		left: 0;
		margin-top: 5px;
		background: rgba($new-purple-tint, 0.9);
		border-radius: 10px;
		padding: 15px;
		pointer-events: none;
		opacity: 0;
		transition: opacity 0.2s ease;
		backdrop-filter: blur(10px);
		cursor: default;
		max-height: 300px;
		overflow-y: scroll;
		box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.1);

		@include respond-to("mobile") {
			width: 150%;
			padding: 5px;
			box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
		}
	}

	&[data-open] {
		z-index: 999;
		.Item-icon {
			transform: rotateZ(-90deg);
		}
		.Item-body {
			opacity: 1;
			pointer-events: all;
		}
	}

	@include respond-to("mobile") {
		padding: 8px;
		gap: 5px;
		.Item-check {
			width: 15px;
			height: 15px;
			flex-shrink: 0;
			flex-grow: 0;
			border-radius: 4px;
			.Site-icon {
				width: 15px;
				height: 15px;
				position: absolute;
				top: 0;
				left: 0;

				&:after {
					font-size: 11px;
				}
			}
		}

		.Item-label {
			word-wrap: break-word;
		}
		.Item-icon,
		.Site-icon {
			width: 25px;
			height: 25px;
		}
	}
}

div:nth-child(even) > .Item--select {
	@include respond-to("mobile") {
		.Item-body {
			left: auto;
			right: 0;
		}
	}
}

.Item--num {
	@extend %u-text--hero;
	color: white;
	.Item-intro {
		display: block;
	}

	.Item-big {
		@extend %u-text--mega;
		position: relative;

		color: rgb(161, 255, 255);

		.Item-grad {
			background: -webkit-linear-gradient(
				rgb(161, 255, 255),
				rgb(69, 45, 118)
			);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
		}
		&.Item-big--hasSup {
			//margin-right:3vw;
		}
		sup {
			color: rgb(123, 191, 217);
			transform: scale(0.5, 0.5);
			position: absolute;
			top: 25%;
			left: 90%;
			display: inline-block;
		}
	}
}
.Item a {
	display: block;

	&.Link--button {
		display: inline-block;
	}
}

.Item--calendar {
	border-top: 1px solid rgba(255, 255, 255, 0.2);
	padding-top: 20px;
	list-style: none;
	.Item-head,
	.Item-body {
		display: inline-block;
		vertical-align: top;
		width: 100%;
		@include respond-to("tablet-up") {
			width: 50%;
		}

		padding-bottom: 20px;
	}

	.Item-date,
	.Item-title {
		display: inline-block;
		vertical-align: top;
	}

	.Item-date {
		width: 30%;
		@include respond-to("mobile") {
			width: 100px;
		}
		position: relative;
		&:before {
			width: 90px;
			height: 90px;
			content: "";
			display: block;
			background: url(/ui/img/icons/calendar.svg) center center no-repeat;
		}

		span {
			position: absolute;
			top: 50%;
			left: 0;
			width: 90px;
			text-align: center;
			padding-top: 20px;
			transform: translate(0%, -50%);
			font-size: 20px;
			color: $new-pink;
		}

		&.Item-date--hasTo {
			span {
				font-size: 15px;
			}
		}
	}
	.Item-title {
		width: 70%;
		@include respond-to("mobile") {
			width: 250px;
		}
		padding-right: 40px;
		padding-top: 10px;
		h1,
		h2,
		h3 {
			@extend %u-text--body;
		}

		h1 {
			color: $midBlue;
		}

		h2 {
			font-style: italic;
			padding-bottom: 10px;
		}

		h3 {
			@extend %u-text--mono;
		}
	}
}

.Item--default {
	.Item-body {
		.Content {
			p {
				@extend %u-text--mono;
				strong {
					@extend %u-text--mono;
					background: $new-pink;
					font-weight: normal;
				}
			}
		}
	}

	.Item-link {
		padding-top: 20px;
		a {
			transition: color 0.4s ease;
			color: $grey;

			.Template--article & {
				color: $darkBlue;
			}
			@extend %u-text--small;
			@extend %u-link--arrow;
		}
	}
}

.Item-media {
	margin-bottom: 15px;
	border-radius: 10px;
	overflow: hidden;
}

.Item--numberList1 {
	.Item-title {
		&:before {
			content: "0" attr(data-number) ".";
			display: inline-block;
			padding-right: 5px;
			vertical-align: top;
			@extend %u-text--body;
		}
		h1 {
			display: inline-block;
			vertical-align: top;
			@extend %u-text--heading;
		}
		padding-bottom: 30px;
	}
	.Item-body {
		@include respond-to("desktop") {
			min-height: 100px;
		}
		* {
			max-width: 300px;
			margin-right: auto;
		}
		margin-bottom: 15px;
	}
	.Item-link {
		@extend %u-link--button;
	}
}

.Item--numberList2 {
	border-top: 1px solid rgba(255, 255, 255, 0.2);
	padding-top: 20px;
	padding-left: 50px;

	position: relative;
	&:before {
		content: attr(data-number) ".";
		display: inline-block;
		position: absolute;
		top: 15px;
		left: 0;
		@extend %u-text--heading;
	}

	.Item-title {
		h1 {
			display: inline-block;
			vertical-align: top;
			@extend %u-text--heading;
			line-height: 100%;
		}
		padding-bottom: 30px;
	}
	.Item-body {
		@include respond-to("desktop") {
			min-height: 100px;
		}
		* {
			max-width: 300px;
			margin-right: auto;
		}
		margin-bottom: 15px;
	}
	.Item-link {
		@extend %u-link--button;
	}
}
.Item--chart {
	padding-right: 15px;

	.Item-clock {
		width: 10px;
		height: 10px;
		border-radius: 50%;

		&:after {
			content: "";
			display: block;
			width: 4px;
			height: 4px;
			border-radius: 50%;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			background: $purple;
		}
		margin-left: -4px !important;
		margin-top: -4px !important;

		.Item-hand {
			width: 2px;
			height: 20px;
			position: absolute;
			left: 4px;
			bottom: 5px;
			background: $purple;
			opacity: 0.7;
			transform-origin: 50% 100%;

			transition: transform 1s ease;

			&.Item-hand--end {
				opacity: 0.3;
				transform: rotate(45deg);

				transition: transform 1.5s 0.2s ease;
			}

			&[data-time="000"] &[data-time="1200"] {
				transform: rotate(0deg);
			}

			&[data-time="100"],
			&[data-time="1300"] {
				transform: rotate(30deg);
			}

			&[data-time="200"],
			&[data-time="1400"] {
				transform: rotate(60deg);
			}

			&[data-time="300"],
			&[data-time="1500"] {
				transform: rotate(90deg);
			}

			&[data-time="400"],
			&[data-time="1600"] {
				transform: rotate(120deg);
			}

			&[data-time="500"],
			&[data-time="1700"] {
				transform: rotate(150deg);
			}

			&[data-time="1730"] {
				transform: rotate(165deg);
			}

			&[data-time="600"],
			&[data-time="1800"] {
				transform: rotate(180deg);
			}

			&[data-time="700"],
			&[data-time="1900"] {
				transform: rotate(210deg);
			}

			&[data-time="800"],
			&[data-time="2000"] {
				transform: rotate(240deg);
			}

			&[data-time="900"],
			&[data-time="2100"] {
				transform: rotate(270deg);
			}

			&[data-time="1000"],
			&[data-time="2200"] {
				transform: rotate(300deg);
			}

			&[data-time="2230"] {
				transform: rotate(315deg);
			}

			&[data-time="1100"],
			&[data-time="2300"] {
				transform: rotate(330deg);
			}
		}
	}

	.Item-media {
		border-right: 1px solid $midBlue;
		height: 100px;
		min-width: 120px;
		position: relative;

		& > * {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			pointer-events: none;
			margin-left: -15px;
		}
	}
	.Item-head {
		text-align: left;
		padding-left: 10px;
		h1 {
			@extend %u-text--small;
		}
		p {
			@extend %u-text--small;
			opacity: 0.7;
		}
	}
}
.Item--screens,
.Item--feature {
	.Item-title {
		h1 {
			@extend %u-text--body;
			transition: color 0.4s ease;
		}
	}

	.Item-body {
		.Content {
			* {
				transition: color 0.4s ease;
			}
			p {
				@extend %u-text--mono;
			}
		}
	}

	.Item-link {
		padding-top: 20px;
		a {
			transition: color 0.4s ease;
			//color: $grey;
			@extend %u-text--mono;
			@extend %u-link--arrow;
		}
	}
	.Site--no-touch & {
		.Item-media {
			border: 1px solid transparent;
			transition: border 0.4s ease;
		}
		.Image {
			transition: box-shadow 0.4s ease;
		}
		&:hover {
			.Item-title h1,
			.Item-body .Content * {
				//color: $grey;
			}

			.Item-media {
				border-color: rgba(255, 255, 255, 0.2);
			}
			.Image {
				box-shadow: 0 0 30px rgba($purple, 0.2),
					0 0 30px rgba($purple, 0.2);
			}

			.Item-link a {
				color: $new-pink;
			}
		}
	}
}

.Item--masonry {
	//background: rgba($darkBlue, 0.2);
	//padding: 15px;

	transition: transform 0.2s ease;

	.Item-date {
		@extend %u-text--mono;
		color: $new-pink;
		display: none;
	}

	.Item-title {
		h1 {
			@extend %u-text--body;
		}
		padding-bottom: 15px;
	}

	.Item-body {
		.Content {
			p {
				@extend %u-text--small;
			}
		}
	}

	.Item-link {
		padding-top: 20px;
		a {
			@extend %u-text--mono;
			@extend %u-link--arrow;
		}
	}
	.Site--no-touch & {
		&:hover {
			transform: scale(1.05, 1.05);
		}
	}
}

.Item--filter {
	.Item-title {
		padding-bottom: 20px;
	}
}

.Item--input {
	padding-bottom: 60px;

	.Item-title {
		* {
			@extend %u-text--small;
		}
		margin-bottom: 10px;
	}

	&.Item--slider {
		.Item-body {
			position: relative;
			margin: 0 10px;
		}

		.rc-slider-track,
		.rc-slider-rail {
			height: 2px;
			border-radius: 1px;
		}
		.rc-slider-dot {
			display: none;
		}
		.rc-slider-track {
			background: $purple;
		}

		.rc-slider-handle {
			border-color: $purple;
			background: $purple;
			margin-top: -6px;
		}

		.rc-slider-mark-text {
			@extend %u-text--small;
		}
	}
}
.Item--gallery {
	.Block--orientation-landscape & {
		&.Item--fit {
			.Image-media {
				background-size: contain;
				background-position: top left;
			}
		}
	}
}
