.Container {
	padding: 0 20px;
	@include pad-for-menu();
	@include respond-to("desktop") {
		padding-right: 40px;
	}

	.Site-modal & {
		@include respond-to("desktop") {
			padding-left: 40px;
		}
	}

	.Template-blocks & {
		padding: 0;
	}
}
