%u-text--mono {
	font-family: "DM Mono";
	font-size: 12px;
	text-transform: uppercase;

	@include respond-to("mobile") {
		font-size: 10px;
	}
}

%u-text--mega {
	font-family: $base-font-family;
	font-size: 10vw;

	@include respond-to("mobile") {
		font-size: 30vw;
	}
}

%u-text--hero {
	font-family: $base-font-family;
	@include font-size(30px);

	@include respond-to("mobile") {
		font-size: 15px;
	}
}

%u-text--title {
	font-size: 50px;
	font-family: $base-font-family;
	letter-spacing: -0.02em;
	@include respond-to("desktop") {
		font-size: 5.5vw;
	}

	@include respond-to("wide") {
		font-size: 80px;
	}

	line-height: 100%;
}

%u-text--intro {
	font-size: 30px;
	font-family: $base-font-family;
	letter-spacing: -0.02em;
	@include respond-to("desktop") {
		font-size: 2.7vw;
	}

	@include respond-to("wide") {
		font-size: 40px;
	}

	line-height: 110%;
}

%u-text--heading {
	font-family: $base-font-family;
	@include font-size(30px);
}

%u-text--subheading {
	@extend %u-text--mono;
	&:before {
		content: "_";
	}
	/*@include respond-to("wide") {
		@include font-size(16px);
	}*/
}

%u-text--body {
	font-family: $base-font-family;
	@include font-size(15px);

	@include respond-to("desktop") {
		@include font-size(18px);
	}

	@include respond-to("wide") {
		//@include font-size(20px);
	}
}

%u-text--small {
	font-family: $base-font-family;
	@include font-size(12px);

	@include respond-to("wide") {
		@include font-size(15px);
	}

	sup {
		font-size: 8px;
	}
}
