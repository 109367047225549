.Burger {
	width: 45px;
	height: 45px;
	position: relative;
	color: white;
	border: 1px solid $new-dark;
	border-radius: 30px;
	transition: background 0.4s ease, border 0.4s ease, box-shadow 0.4s ease;
	.Site--scrolled & {
		background: white;
		border-color: transparent;
		box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
	}

	@include respond-to("mobile") {
		background: white;
		border-color: transparent;
		box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
	}
	.Site--menu & {
		background: transparent;
		border-color: transparent;
		box-shadow: none;
	}

	&:after {
		content: "Menu";
		display: none;
		position: absolute;
		top: 50%;
		left: 100%;
		margin-left: 10px;
		transform: translateY(-50%);
		margin-top: 1px;

		@include respond-to("desktop") {
			//display:block;
		}
	}

	&.Burger--basic {
		&:after {
			display: none !important;
		}
	}
	.Site--menu & {
		&:after {
			content: "Close";
		}
	}
}
.Burger-line {
	display: block;
	width: 20px;
	height: 1px;
	background: $new-dark;
	position: absolute;
	//opacity:0.5;
	top: 50%;
	left: 12px;
	transform: translateY(-50%);

	&.Burger-line--top {
		transition: background 0.5s ease, transform 0.5s 0.5s ease,
			opacity 0s 0.5s;
		transform: translateY(-9px);
	}
	&.Burger-line--bottom {
		transition: background 0.5s ease, transform 0.5s 0.5s ease,
			opacity 0s 0.5s;
		transform: translateY(7px);
	}

	&.Burger-line--middle {
		transition: opacity 0s 0.5s;
		transform: translateY(-1px);
	}

	&.Burger-line--x1,
	&.Burger-line--x2 {
		opacity: 0;
		transition: background 1s $easeOutExpo, transform 1s $easeOutExpo,
			opacity 0s 0.5s;
	}
}

.Site--menu,
.Burger--cross {
	.Burger-line {
		background: white;
		&.Burger-line--top {
			transition: background 0.5s ease, transform 0.5s ease,
				opacity 0s 0.5s;
			transform: translateY(-50%);
			opacity: 0;
		}
		&.Burger-line--bottom {
			transition: background 0.5s ease, transform 0.5s ease,
				opacity 0s 0.5s;
			transform: translateY(-50%);
			opacity: 0;
		}

		&.Burger-line--middle {
			opacity: 0;
		}

		&.Burger-line--x1 {
			opacity: 1;
			transition: background 1s $easeOutExpo,
				transform 1s 0.5s $easeOutExpo;
			transform: translateY(-50%) rotate(-45deg);
		}

		&.Burger-line--x2 {
			opacity: 1;
			transition: background 1s $easeOutExpo,
				transform 1s 0.5s $easeOutExpo;
			transform: translateY(-50%) rotate(45deg);
		}
	}
}
