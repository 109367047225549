%u-link--button {
	@extend %u-text--mono;
	display: inline-block;
	height: 45px;
	line-height: 45px;
	padding: 0 20px;
	padding-right: 60px;
	min-width: 150px;

	@include respond-to("mobile") {
		height: 40px;
		line-height: 40px;
		padding: 0 15px;
		padding-right: 45px;
		min-width: 70px;
	}
	text-align: left;
	border: 1px solid $new-dark;
	//border:2px solid rgb(128,40,211);
	border-radius: 25px;

	color: $new-dark;

	transition: background 0.4s ease, border 0.4s ease;
	cursor: pointer;
	&:hover {
		color: white;
		background: $new-dark;
	}

	margin-right: 15px;
	position: relative;
	&:after {
		content: "arrow-right";
		text-transform: lowercase;
		font-family: "Linearicons";
		font-size: 20px;
		position: absolute;
		top: 50%;
		right: 20px;
		transform: translateY(-50%);

		@include respond-to("mobile") {
			right: 15px;
		}
	}

	&[data-icon] {
		&:after {
			content: attr(data-icon);
		}
	}
}

%u-link--blurButton {
	background: rgba($new-light, 0.7);
	backdrop-filter: blur(5px);
	&:hover {
		color: white;
		background: $new-dark;
	}
}

%u-link--underline {
	border-bottom: 1px solid;
	transition: border 0.4s ease;

	.Site--no-touch & {
		&:hover {
			border-color: transparent;
		}
	}
}

%u-link--arrow {
	position: relative;
	display: inline-block;
	&:after {
		@extend %u-iconfont;
		@extend %u-iconfont--arrow-right;
		font-size: 25px;
		position: absolute;
		top: 50%;
		left: 100%;
		margin-left: 5px;
		height: 30px;
		line-height: 30px;
		transform: translate(0, -50%);
	}
}
