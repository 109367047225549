.Content {
	h1 {
		@extend %u-text--title;
		padding-bottom: 0.5em;
		width: 50%;
		//text-shadow:3px 3px #060626;
	}

	h3 {
		@extend %u-text--subheading;
		//letter-spacing:0.05em;
		margin-bottom: 1em;

		background: $new-pink;
		display: inline-block;
		padding: 0.3em 1em;
	}
	h4 {
		@extend %u-text--mono;
	}
	h2 {
		@extend %u-text--intro;
		padding: 0.2em 0;
		width: 60%;

		@include respond-to("mobile") {
			width: auto;
		}
	}

	p,
	li,
	h5,
	h6 {
		@extend %u-text--body;

		padding-bottom: 1em;
		max-width: 500px;
	}

	h4,
	h5,
	h6 {
		a {
			@extend %u-link--underline;
		}
	}

	p a {
		@extend %u-link--underline;
	}

	strong {
		font-weight: bold;
	}

	dl {
		* {
			@extend %u-text--small;
			line-height: 150%;
		}

		padding-bottom: 50px;

		li {
			list-style: none;
			padding-bottom: 0px;

			* {
				display: inline;
			}
		}

		dt {
			@extend %u-text--subheading;
			&:after {
				content: ":";
				display: inline;
				padding-right: 15px;
			}
		}
		dd {
		}
		p:last-child {
			padding-bottom: 0;
		}
	}

	ul {
		@extend %u-list--dashed;
	}
}

.Content.Content--caption {
	* {
		@extend %u-text--mono;
		line-height: 150%;
	}

	strong {
		.Template--article & {
		}
	}
}

.Content.Content--popup {
	* {
		@extend %u-text--small;
		line-height: 150%;
	}

	h1 {
		@extend %u-text--body;
		margin-bottom: 10px;
		padding-bottom: 0;
	}

	a {
		@extend %u-link--arrow;
	}
}

.Content--spec {
	padding-top: 30px;
	h3 {
		@include font-size(12px);
		padding-bottom: 4px;
	}
}

.Content--project {
	h1,
	p {
		padding-bottom: 20px;
	}
}

.Content--thumb {
	@include pad-ratio(600, 400);
	position: relative;
	h1 {
		@extend %u-layout-centre;
		font-size: 1vw;
		text-align: center;
		width: 100%;
	}
}

.Content--hero {
	* {
		@extend %u-text--hero;
		text-align: center;
	}
}
