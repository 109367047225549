.List--menu {
  @extend %u-text--intro;

  a {
    border: none !important;
    display: block;
    position: relative;
    &:after {
      font-family: "linearicons";
      content: "arrow-right";
      font-size: 20px;
      position: absolute;
      top: 50%;
      right: 40px;
      transform: translate(0, -50%);
      transition: transform 0.4s ease;
    }

    transition: color 0.4s ease;

    &:hover {
      &:after {
        transform: translate(5px, -50%);
      }

      color: $new-pink;
    }
  }
  position: relative;

  li {
    padding-bottom: 0.5em;
    display: inline-block;
    width: 50%;
    vertical-align: top;

    @include respond-to("mobile") {
      width: 100%;
    }
  }

  padding: 2/18 * 100vw;

  padding-top: 0.5/18 * 100vw;
  padding-bottom: 0.5/18 * 100vw;

  @include respond-to("mobile") {
    padding: 40px;
  }
}

.List--explore {
  @extend %u-text--intro;

  a {
    border: none !important;
    display: inline-block;
    position: relative;
    &:after {
      font-family: "linearicons";
      content: "arrow-right";
      font-size: 20px;
      position: absolute;
      top: 50%;
      left: 110%;
      transform: translate(0, -50%);
      transition: transform 0.4s ease, opacity 0.4s ease;
      opacity: 0;
    }

    transition: color 0.4s ease;

    &:hover {
      &:after {
        opacity: 1;
        transform: translate(5px, -50%);
      }

      color: $new-pink;
    }
  }
  position: relative;

  li {
    padding-bottom: 0.2em;
  }

  padding-bottom: 0.5/18 * 100vw;
}

.List--channels {
  @extend %u-text--mono;
  //padding-top: 0.5/18 * 100vw;
  a {
    &:before {
      content: "_";
    }
    border: none !important;
  }

  padding-left: 2/18 * 100vw;
}
.List--nav {
  padding: 20px;
  @include pad-for-menu;

  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  @extend %u-text--title;

  transition: transform 1s ease, filter 1s ease;

  //filter:blur(3px);

  .Site--menu & {
    filter: none;
    transform: translateY(-50%);
  }

  a {
    transition: opacity 0.4s ease, border 0.4s ease;
    //border-bottom: 2px solid transparent;
    display: inline-block;
    position: relative;

    &:after {
      font-family: "linearicons";
      content: "arrow-right";
      font-size: 50px;
      position: absolute;
      top: 50%;
      left: 105%;
      transform: translate(0, -50%);
      transition: transform 0.4s ease, opacity 0.4s ease;
      opacity: 0;
    }

    transition: color 0.4s ease;

    &:hover {
      &:after {
        opacity: 1;
        transform: translate(5px, -50%);
      }

      color: white;
    }

    &.is-active {
      color: $new-pink;
    }

    &.is-inactive {
      // opacity: 1;
    }
  }

  .Site--no-touch & {
    a:hover {
      opacity: 1;
    }
  }
  li {
    transition: transform 1s ease, opacity 1s ease;
    transform: translateY(30%);
    opacity: 0;
    padding-bottom: 10px;

    &:nth-child(1) {
      transform: translateY(10%);
    }

    &:nth-child(2) {
      transform: translateY(15%);
    }

    &:nth-child(3) {
      transform: translateY(20%);
    }

    .Site--menu & {
      transform: none;
      opacity: 1;
    }
  }
}

.List--numberList1 {
  margin-left: -40px;
  & > li {
    display: inline-block;

    vertical-align: top;
    padding-left: 40px;
    padding-right: 40px;
    width: 100%;
    margin-bottom: 20px;
    &:before {
      content: "";
      display: block;
      border-top: 1px solid rgba(255, 255, 255, 0.2);
      margin-bottom: 20px;
    }

    padding-bottom: 50px;

    @include respond-to("desktop") {
      &:before {
        display: none;
      }
      border-right: 1px solid rgba(255, 255, 255, 0.2);

      &.Block-item--full {
        border-right: none;
      }

      &.Block-item--half {
        width: 1/2 * 100%;
        &:nth-child(even) {
          border-right: none;
        }
      }

      &.Block-item--onethird {
        width: 1/3 * 100%;
        &:nth-child(3n + 3) {
          border-right: none;
        }
      }

      border-top: none;
    }
  }
}

.List--inline {
  @extend %u-list--inline;
}

.List--default,
.List--screens,
.List--feature {
  margin-left: -40px;

  .Block-item {
    display: inline-block;
    padding-left: 40px;
    width: 100%;
    vertical-align: top;
    padding-bottom: 40px;
  }
  .Block-item--onethird {
    @include respond-to("tablet-up") {
      width: 1/3 * 100%;
    }
  }

  .Block-item--half {
    @include respond-to("tablet-up") {
      width: 1/2 * 100%;
    }
  }
}

.List--screens {
  @include respond-to("mobile") {
    margin-left: -20px;

    .Block-item {
      width: 50%;
      padding-left: 20px;
    }
  }
}

.List--numberList2 {
  margin-left: -40px;
  & > li {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    padding-left: 40px;
    padding-bottom: 20px;
    @include respond-to("tablet-up") {
      &.Block-item--half {
        width: 1/2 * 100%;
      }

      &.Block-item--onethird {
        width: 1/3 * 100%;
      }
    }
  }
}

.List--masonry {
  margin-left: -40px;

  .List-sizer,
  .List-item {
    width: 1/3 * 100%;
    padding-left: 40px;
    padding-bottom: 40px;

    @include respond-to("mobile") {
      width: 100%;
    }
  }
}

.List--radio {
  li {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    width: 50%;
    display: inline-block;
    &.List-group {
      @extend %u-text--small;
      padding: 8px;
      padding-left: 0;
      width: 100%;

      & + li:last-child {
        width: 100%;
      }
    }
    &.List-single {
      width: 100%;
    }
  }
}

.List--icons {
  li {
    display: inline-block;
  }
}
