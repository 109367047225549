@keyframes colors {
	0% {
		fill: $pink;
	}
	50% {
		fill: #232299;
	}
	/*75%{
		fill:#f8f382;
	}*/
	100% {
		fill: $pink;
	}
}

@keyframes white {
	0% {
		fill: #000;
	}
	100% {
		fill: white;
	}
}

@keyframes grey {
	0% {
		fill: #000;
	}

	100% {
		fill: #999;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

$w: 20vw;

.Intro {
	position: relative;
	width: 100%;
	height: 100%;
	display: block;
	background: rgba($darkBlue, 0.2);
	.Image {
		box-shadow: 0 0 100px rgba(128, 40, 211, 0.15);
	}
}

.Intro-strap {
	text-align: center;
	transform: translate(-5%, -0%);
	margin: 0;
	padding: 0;
	line-height: 100%;
	padding-top: 0px;
	//text-align:center;
	width: 100%;
	opacity: 0;

	padding: 0;
	transform: translate(0, -0em);

	@extend %u-text--subheading;
	&:before {
		display: none;
	}

	margin-left: auto;
	animation: fadeIn 1s 0.5s ease forwards;
	line-height: 120%;
	white-space: nowrap;
	span {
		color: rgba(255, 255, 255, 0.5);
		color: $pink;
		color: #a554ff;
		display: block;
		padding-top: 5px;
		white-space: nowrap;
		//opacity:0.5;
	}

	@include respond-to("mobile") {
		width: 45vw;
		padding-right: 0;
		transform: translate(0%, -0.8em);
		font-size: 12px;
		span {
			padding-top: 0;
		}
	}
}

.Intro-message {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate3d(-50%, -50%, 0);
	font-size: 4vw;
	color: white;
	z-index: 2;
	text-align: left;
	line-height: 120%;
	transition: transform 0.7s ease, opacity 0.7s ease;
	pointer-events: none;
	opacity: 0;

	animation: fadeIn 1s 0.3s ease forwards;

	&.Intro-message--logo {
		.Site--introComplete & {
			opacity: 0;
		}

		svg {
			width: $w;
			height: $w * 0.24;
			display: block;
			margin-left: auto;
			margin-bottom: 20px;

			@include respond-to("mobile") {
				width: 45vw;
				height: 45vw * 0.24;
			}

			max-width: 500px;
			max-height: 500px * 0.24;

			.st1 {
				animation: colors 2s ease infinite;

				&.s1 {
					animation: colors 2s 0s ease infinite;
				}
				&.s2 {
					animation: colors 2s 0.1s ease infinite;
				}
				&.s3 {
					animation: colors 2s 0.2s ease infinite;
				}
				&.s4 {
					animation: colors 2s 0.3s ease infinite;
				}
				&.s5 {
					animation: colors 2s 0.4s ease infinite;
				}
			}
		}
	}
}

.Intro-image {
	position: absolute;
	top: 40px;
	right: 40px;
	left: 33.33333%;
	bottom: 80px;
	opacity: 0;
	transition: transform 0.7s ease, opacity 0.7s ease;
	transform: translateY(20px);
	.Site--introComplete & {
		opacity: 1;
		transform: none;
	}
}
